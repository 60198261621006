import {
  AccountancyValueAddedTaxModule,
  AssemblingOrderModule,
  BoxModule,
  ClientModule,
  ClientOrderModule,
  ClientRegistrationRequestModule,
  CompanyBankAccountModule,
  ContractorModule,
  CountryModule,
  DeliveryFeeModule,
  DeliveryMethodModule,
  DictionaryCategoryModule,
  DictionaryModule,
  DocumentNumberModule,
  DocumentTypeModule,
  EmployeeModule,
  ErrandModule,
  ExportConfigModule,
  InquiryModule,
  LabelModule,
  LanguageModule,
  LeadModule,
  LogModule,
  MachineModule,
  PlaceEventModule,
  PlaceModule,
  ProductCollectionModule,
  ProductColorModule,
  ProductColorTypeModule,
  ProductFilterModule,
  ProductGroupModule,
  ProductKindModule,
  ProductModule,
  ProductSeriesModule,
  ProductSpeciesModule,
  ProductTypeModule,
  ProductVersionModule,
  ProductionDemandModule,
  ProductionOrderModule,
  RoleModule,
  ShipmentAddressModule,
  ShipmentModule,
  SlideModule,
  TariffModule,
  TaskModule,
  TechnologyModule,
  TextModule,
  UnitModule,
  WarehouseModule,
  WorkplaceModule,
} from 'Modules/CTMModules';

const moduleDictionaries = moduleId => ({
  type: 'LINK',
  title: DictionaryModule.configuration.name,
  iconClass: 'mdi mdi-dns',
  to: `${DictionaryModule.listUrl}/${moduleId}`,
  matchRegexp: `.*(add|list|edit)/${moduleId}.*`,
});
const moduleFormConfiguration = moduleId => ({
  type: 'LINK',
  title: 'Ustawienia formularza',
  to: `/configuration/fields/${moduleId}`,
  role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
});

const moduleBaseLink = module => ({
  type: 'LINK',
  title: module.configuration.name,
  to: module.listUrl,
  role: module.configuration.role ? `ROLE_SHOW_${module.configuration.role}|ROLE_EDIT_${module.configuration.role}` : null,
});

export default [
  {
    type: 'LINK',
    title: 'Dashboard',
    iconClass: 'bx bx-home-circle',
    to: '/#',
  },
  {
    type: 'DIVIDER',
    title: 'Biurko',
  },
  {
    type: 'LINK',
    title: 'Zadania',
    iconClass: 'mdi mdi-file-tree',
    to: '/#',
  },
  {
    type: 'LINK',
    title: 'Notatki',
    iconClass: 'mdi mdi-note',
    to: '/notes',
  },
  {
    type: 'LINK',
    title: 'Kalendarz',
    iconClass: 'mdi mdi-calendar',
    to: '/modules/calendar/calendar',
  },
  {
    type: 'DIVIDER',
    title: 'Handel',
  },
  {
    type: 'LINK',
    title: 'Oferty dla klientów',
    iconClass: 'bx bx-add-to-queue',
    to: '/#',
  },
  {
    type: 'LINK',
    title: ClientOrderModule.configuration.name,
    iconClass: 'bx bx-calendar-check',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ClientOrderModule.listUrl,
        role: 'ROLE_SHOW_' + ClientOrderModule.configuration.role,
      },
      {
        type: 'CREATE_NEW_LINK',
        title: 'Dodaj',
        moduleId: ClientOrderModule.configuration.id,
        iconClass: 'bx bx-plus-circle',
        to: ClientOrderModule.createUrl,
        role: 'ROLE_CREATE_' + ClientOrderModule.configuration.role,
      },
      moduleDictionaries(ClientOrderModule.configuration.id),
    ],
  },
  {
    ...moduleBaseLink(InquiryModule),
    iconClass: 'bx bx-shopping-bag',
  },
  {
    type: 'LINK',
    title: 'Zam. do dostawców',
    iconClass: 'bx bx-car',
    to: '/#',
  },
  {
    type: 'DIVIDER',
    title: 'Kartoteka',
  },
  {
    type: 'LINK',
    title: ProductModule.configuration.name,
    iconClass: 'bx bx-barcode',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ProductModule.listUrl,
        role: 'ROLE_SHOW_' + ProductModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ProductModule.createUrl,
        role: 'ROLE_CREATE_' + ProductModule.configuration.role,
      },
      moduleDictionaries(ProductModule.configuration.id),
      {
        type: 'LINK',
        title: 'Raporty',
        iconClass: 'bx bx-reply',
        to: '/#',
        children: [
          {
            type: 'LINK',
            title: 'Cennik',
            to: '/manufacture/report/product-prices',
            role: 'ROLE_SHOW_MANUFACTURE_PRODUCT',
          },
        ],
      },
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [
          {
            ...moduleBaseLink(TariffModule),
            iconClass: 'bx bx-price',
          },
          moduleFormConfiguration(ProductModule.configuration.id),
          moduleBaseLink(ProductGroupModule),
          moduleBaseLink(ProductColorTypeModule),
          moduleBaseLink(ProductColorModule),
          moduleBaseLink(ProductVersionModule),
          moduleBaseLink(ProductKindModule),
          moduleBaseLink(ProductSpeciesModule),
          moduleBaseLink(ProductSeriesModule),
          moduleBaseLink(UnitModule),
          moduleBaseLink(ProductTypeModule),
          moduleBaseLink(ProductFilterModule),
        ],
      },
    ],
  },
  {
    type: 'LINK',
    title: ProductCollectionModule.configuration.name,
    iconClass: 'bx bx-collection',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ProductCollectionModule.listUrl,
        role: 'ROLE_SHOW_' + ProductCollectionModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ProductCollectionModule.createUrl,
        role: 'ROLE_CREATE_' + ProductCollectionModule.configuration.role,
      },
      moduleDictionaries(ProductCollectionModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(ProductCollectionModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: ContractorModule.configuration.name,
    iconClass: 'bx bx-user',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(ContractorModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        ...moduleBaseLink(ClientModule),
        title: 'Osoby zakupowe',
        iconClass: 'bx bx-list-ul',
      },
      {
        ...moduleBaseLink(ClientRegistrationRequestModule),
        title: 'Rejestracje oczekujące',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ContractorModule.createUrl,
        role: `ROLE_CREATE_${ContractorModule.configuration.role}`,
      },
      moduleDictionaries(ContractorModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [
          moduleFormConfiguration(ContractorModule.configuration.id),
          moduleBaseLink(DocumentTypeModule),
          moduleBaseLink(DocumentNumberModule),
          moduleBaseLink(ShipmentAddressModule),
        ],
      },
    ],
  },
  {
    type: 'LINK',
    title: LeadModule.configuration.name,
    iconClass: 'bx bx-clinic',
    to: '/#',
    role: `ROLE_SHOW_${LeadModule.configuration.role}`,
    children: [
      {
        ...moduleBaseLink(LeadModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: LeadModule.createUrl,
        role: `ROLE_CREATE_${LeadModule.configuration.role}`,
      },
      {
        type: 'LINK',
        title: 'Import',
        iconClass: 'bx bx-file',
        to: '/modules/' + LeadModule.configuration.urlPrefix + '/import',
        role: `ROLE_CREATE_${LeadModule.configuration.role}`,
      },
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(LeadModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: EmployeeModule.configuration.name,
    iconClass: 'bx bx-briefcase',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(EmployeeModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: EmployeeModule.createUrl,
        role: `ROLE_CREATE_${EmployeeModule.configuration.role}`,
      },
      moduleDictionaries(EmployeeModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [
          moduleFormConfiguration(EmployeeModule.configuration.id),
          moduleBaseLink(DocumentTypeModule),
          moduleBaseLink(DocumentNumberModule),
        ],
      },
    ],
  },
  {
    type: 'LINK',
    title: 'Biblioteka zdjęć',
    iconClass: 'bx bx-image',
    to: '/core/photo-library',
  },
  {
    type: 'DIVIDER',
    title: 'Magazyn',
  },
  {
    type: 'LINK',
    title: 'Dokumenty',
    iconClass: 'bx bx-file-find',
    to: '/#',
    role: 'ROLE_CREATE_WAREHOUSE_DOCUMENT',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        role: 'ROLE_CREATE_WAREHOUSE_DOCUMENT',
        to: '/warehouse/documents',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        role: 'ROLE_CREATE_WAREHOUSE_DOCUMENT',
        to: '/warehouse/documents/add',
      },
      moduleDictionaries('30bdde2b-8c44-42bd-a77f-07c797b2b00d'),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [
          moduleFormConfiguration('30bdde2b-8c44-42bd-a77f-07c797b2b00d'),
          moduleBaseLink(DocumentTypeModule),
          moduleBaseLink(DocumentNumberModule),
        ],
      },
    ],
  },
  {
    type: 'LINK',
    title: WarehouseModule.configuration.name,
    iconClass: 'bx bx-package',
    to: '/#',
    role: `ROLE_SHOW_${WarehouseModule.configuration.role}|ROLE_CREATE_${WarehouseModule.configuration.role}`,
    children: [
      {
        ...moduleBaseLink(WarehouseModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        role: `ROLE_SHOW_${WarehouseModule.configuration.role}`,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        role: `ROLE_CREATE_${WarehouseModule.configuration.role}`,
        to: WarehouseModule.createUrl,
      },
      {
        ...moduleBaseLink(PlaceModule),
        iconClass: 'bx bx-package',
      },
      {
        ...moduleBaseLink(PlaceEventModule),
        iconClass: 'bx bx-move',
      },
      moduleDictionaries(WarehouseModule.configuration.id),
      {
        type: 'LINK',
        title: 'Zapotrzebowanie',
        iconClass: 'bx bx-list-plus',
        to: '/manufacture/products-demand-list',
      },
      {
        type: 'LINK',
        title: 'Importy',
        iconClass: 'bx bx-file',
        to: '/#',
        children: [
          {
            type: 'LINK',
            title: 'Stany miejscowe i ceny',
            to: '/warehouse/import/store',
            role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
          },
        ],
      },
      {
        type: 'LINK',
        title: 'Raporty',
        iconClass: 'bx bx-reply',
        to: '/#',
        children: [
          {
            type: 'LINK',
            title: 'Stany miejscowe i ceny',
            to: '/warehouse/report/store-items',
            role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
          },
          {
            type: 'LINK',
            title: 'Stany i zapotrzebowanie',
            to: '/warehouse/report/product-stores',
            role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
          },
          {
            type: 'LINK',
            title: 'Wartości magazynów',
            to: '/warehouse/report/warehouse-amounts',
            role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
          },
        ],
      },
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(WarehouseModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: AssemblingOrderModule.configuration.name,
    iconClass: 'bx bx-git-merge',
    to: '/#',
    role: `ROLE_SHOW_${AssemblingOrderModule.configuration.role}|ROLE_CREATE_${AssemblingOrderModule.configuration.role}`,
    children: [
      {
        ...moduleBaseLink(AssemblingOrderModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        role: `ROLE_SHOW_${AssemblingOrderModule.configuration.role}`,
      },
      moduleDictionaries(AssemblingOrderModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(AssemblingOrderModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: ShipmentModule.configuration.name,
    iconClass: 'bx bx-calendar-check',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ShipmentModule.listUrl,
        role: 'ROLE_SHOW_' + ShipmentModule.configuration.role,
      },
      moduleDictionaries(ShipmentModule.configuration.id),
    ],
  },
  {
    type: 'LINK',
    title: BoxModule.configuration.name,
    iconClass: 'bx bx-box',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: BoxModule.listUrl,
        role: 'ROLE_SHOW_' + WarehouseModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: BoxModule.createUrl,
        role: 'ROLE_CREATE_' + WarehouseModule.configuration.role,
      },
      moduleDictionaries(BoxModule.configuration.id),
    ],
  },
  {
    type: 'DIVIDER',
    title: 'Produkcja',
  },
  {
    type: 'LINK',
    title: ProductionOrderModule.configuration.name,
    iconClass: 'bx bx-git-pull-request',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ProductionOrderModule.listUrl,
        role: 'ROLE_SHOW_' + ProductionOrderModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ProductionOrderModule.createUrl,
        role: 'ROLE_CREATE_' + ProductionOrderModule.configuration.role,
      },
      moduleDictionaries(ProductionOrderModule.configuration.id),
    ],
  },
  {
    type: 'LINK',
    title: ProductionDemandModule.configuration.name,
    iconClass: 'bx bx-reset',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ProductionDemandModule.listUrl,
        role: 'ROLE_SHOW_' + ProductionDemandModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ProductionOrderModule.createUrl,
        role: 'ROLE_CREATE_' + ProductionDemandModule.configuration.role,
      },
      moduleDictionaries(ProductionDemandModule.configuration.id),
    ],
  },
  {
    type: 'LINK',
    title: ErrandModule.configuration.name,
    iconClass: 'bx bx-calendar-check',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: ErrandModule.listUrl,
        role: 'ROLE_SHOW_' + ErrandModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ErrandModule.createUrl,
        role: 'ROLE_CREATE_' + ErrandModule.configuration.role,
      },
      moduleDictionaries(ErrandModule.configuration.id),
    ],
  },
  {
    type: 'LINK',
    title: TaskModule.configuration.name,
    iconClass: 'bx bx-task',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: TaskModule.listUrl,
        role: 'ROLE_SHOW_' + TaskModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: TaskModule.createUrl,
        role: 'ROLE_CREATE_' + TaskModule.configuration.role,
      },
    ],
  },
  {
    type: 'LINK',
    title: TechnologyModule.configuration.name,
    iconClass: 'bx bx-aperture',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(TechnologyModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: TechnologyModule.createUrl,
        role: `ROLE_CREATE_${TechnologyModule.configuration.role}`,
      },
      moduleDictionaries(TechnologyModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(TechnologyModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: WorkplaceModule.configuration.name,
    iconClass: 'mdi mdi-account-hard-hat',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(WorkplaceModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: WorkplaceModule.createUrl,
        role: `ROLE_CREATE_${WorkplaceModule.configuration.role}`,
      },
      moduleDictionaries(WorkplaceModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(WorkplaceModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: MachineModule.configuration.name,
    iconClass: 'mdi mdi-slot-machine',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(MachineModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: MachineModule.createUrl,
        role: `ROLE_CREATE_${MachineModule.configuration.role}`,
      },
      moduleDictionaries(MachineModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleFormConfiguration(MachineModule.configuration.id)],
      },
    ],
  },
  {
    type: 'LINK',
    title: LabelModule.configuration.name,
    iconClass: 'bx bx-barcode',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
        to: LabelModule.listUrl,
        role: 'ROLE_SHOW_' + LabelModule.configuration.role,
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: LabelModule.createUrl,
        role: 'ROLE_CREATE_' + LabelModule.configuration.role,
      },
    ],
  },
  {
    type: 'DIVIDER',
    title: 'Komunikacja',
  },
  {
    type: 'LINK',
    title: 'Kontakty',
    iconClass: 'mdi mdi-card-account-phone',
    to: '/#',
  },
  {
    type: 'LINK',
    title: 'Chat',
    iconClass: 'mdi mdi-chat',
    to: '/chat',
  },
  {
    type: 'LINK',
    title: 'E-mail',
    iconClass: 'mdi mdi-mail',
    to: '/#',
  },
  {
    ...moduleBaseLink(TextModule),
    iconClass: 'mdi mdi-comment-text',
  },
  {
    type: 'DIVIDER',
    title: 'DOSTAWY',
  },
  {
    ...moduleBaseLink(DeliveryMethodModule),
    iconClass: 'mdi mdi-truck-delivery',
  },
  {
    ...moduleBaseLink(DeliveryFeeModule),
    iconClass: 'mdi mdi-play-box',
  },
  {
    type: 'DIVIDER',
    title: 'Konfiguracja systemu',
  },
  {
    type: 'LINK',
    title: 'Konfiguracja',
    iconClass: 'mdi mdi-cogs',
    to: '/#',
    children: [
      {
        type: 'LINK',
        title: 'Ogólne',
        iconClass: 'mdi mdi-cogs',
        to: '/configuration/general',
        role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
      },
      {
        type: 'LINK',
        title: 'Pola globalne',
        iconClass: 'mdi mdi-focus-field',
        to: '/configuration/system-field',
        role: 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION',
      },
      {
        ...moduleBaseLink(CompanyBankAccountModule),
        iconClass: 'mdi mdi-bank',
      },
      {
        ...moduleBaseLink(AccountancyValueAddedTaxModule),
        iconClass: 'mdi mdi-tag',
      },
      {
        ...moduleBaseLink(LanguageModule),
        iconClass: 'mdi mdi-web',
      },
      {
        ...moduleBaseLink(CountryModule),
        iconClass: 'mdi mdi-earth',
      },
      {
        ...moduleBaseLink(SlideModule),
        iconClass: 'mdi mdi-slide',
      },
    ],
  },
  {
    type: 'LINK',
    title: DictionaryModule.configuration.name,
    iconClass: 'mdi mdi-dns',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(DictionaryModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: DictionaryModule.createUrl,
        role: `ROLE_CREATE_${DictionaryModule.configuration.role}`,
      },
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleBaseLink(DictionaryCategoryModule)],
      },
    ],
  },
  {
    type: 'LINK',
    title: 'Role',
    iconClass: 'bx bx-lock',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(RoleModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: RoleModule.createUrl,
        role: `ROLE_CREATE_${RoleModule.configuration.role}`,
      },
      moduleDictionaries(RoleModule.configuration.id),
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [
          moduleFormConfiguration(RoleModule.configuration.id),
          moduleBaseLink(DocumentTypeModule),
          moduleBaseLink(DocumentNumberModule),
        ],
      },
    ],
  },
  {
    type: 'LINK',
    title: 'Logi',
    iconClass: 'bx bx-file-find',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(LogModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
    ],
  },
  {
    type: 'LINK',
    title: ExportConfigModule.configuration.name,
    iconClass: 'mdi mdi-database-export',
    to: '/#',
    children: [
      {
        ...moduleBaseLink(ExportConfigModule),
        title: 'Lista',
        iconClass: 'bx bx-list-ul',
      },
      {
        type: 'LINK',
        title: 'Dodaj',
        iconClass: 'bx bx-plus-circle',
        to: ExportConfigModule.createUrl,
        role: `ROLE_CREATE_${ExportConfigModule.configuration.role}`,
      },
      {
        type: 'LINK',
        title: 'Konfiguracja',
        iconClass: 'bx bx-cog',
        to: '/#',
        children: [moduleBaseLink(ExportConfigModule)],
      },
    ],
  },
];
