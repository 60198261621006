import classnames from 'classnames';
import ModuleBoard from 'components/Module/ModuleBoard';
import ModuleList from 'components/Module/ModuleList';
import Breadcrumbs from 'components/Theme/Common/Breadcrumb';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';

const VIEW_LIST = 'LIST';
const VIEW_BOARD = 'BOARD';

const List: FC<{ forceModule?: string }> = ({ forceModule }) => {
  const { module } = useParams<{ module?: string }>();

  if (!(module ?? forceModule)) {
    throw 'Module attr not found ';
  }
  const crudModule = useModuleContext(module ?? forceModule ?? '');

  if (!crudModule) {
    throw 'Module not found ' + module;
  }

  const cacheKey = (): string => `_ctm_list_view_V1_${window.btoa(crudModule?.configuration.urlPrefix)}`;
  const [view, setView] = useState<string>(localStorage.getItem(cacheKey()) ?? VIEW_LIST);

  const crudShowRole = crudModule?.configuration.list?.role ?? crudModule?.configuration.role;
  const role = crudShowRole ? 'ROLE_SHOW_' + crudShowRole : 'ROLE_EMPLOYEE';
  const ListComponent = crudModule.configuration.list?.inlineComponentOverride ?? null;

  const updateView = (view: string): void => {
    setView(view);
    localStorage.setItem(cacheKey(), view);
  };

  return (
    <SecuredView role={role} displayError={true}>
      <div className="container-fluid">
        <Breadcrumbs title={crudModule.configuration.name} breadcrumbItem={`${crudModule.configuration.name} - lista`} />
        {crudModule.configuration?.list?.board && (
          <div className={'align-self-center justify-content-end text-end mb-2'}>
            <SecuredView role={`ROLE_CREATE_${crudModule.configuration.role}`}>
              {view === VIEW_BOARD && !crudModule.configuration?.form?.disableCreateNewRecord && (
                <Link to={crudModule.createUrl} className="btn btn-primary" color="primary">
                  <i className="mdi mdi-plus" /> Dodaj nowy
                </Link>
              )}
            </SecuredView>
            <ButtonGroup className="ml-1">
              <Button
                onClick={() => updateView(VIEW_LIST)}
                className={classnames('btn btn-primary', { 'btn-success': view === VIEW_LIST })}
              >
                <i className="mdi mdi-view-list" />
              </Button>
              <Button
                onClick={() => updateView(VIEW_BOARD)}
                className={classnames('btn btn-primary', { 'btn-success': view === VIEW_BOARD })}
              >
                <i className="mdi mdi-view-dashboard" />
              </Button>
            </ButtonGroup>
          </div>
        )}
        {ListComponent && <ListComponent key={crudModule.configuration.urlPrefix} />}
        {!ListComponent && view === VIEW_LIST && (
          <ModuleList
            key={crudModule.configuration.urlPrefix}
            moduleName={module ?? forceModule ?? ''}
            defaultFilters={undefined}
            overrideFormProps={{}}
            useModal={false}
          />
        )}
        {!ListComponent && view === VIEW_BOARD && (
          <ModuleBoard key={crudModule.configuration.urlPrefix} moduleName={module ?? forceModule ?? ''} />
        )}
      </div>
    </SecuredView>
  );
};

export default List;
